import { permissions } from '@mpk/shared/domain';

/**
 * Permission for the industry catalogs
 */
enum IndustryCatalogsPermissionsEnum {
  None = 0,
  /**
   * Industry catalogs in catalog management can be viewed
   */
  ViewIndustryCatalogs = 1,

  /**
   * Industry catalogs in catalog management can be viewed and managed
   */
  ManageIndustryCatalogs = 2 | ViewIndustryCatalogs,
}

export const IndustryCatalogsPermissions = permissions(IndustryCatalogsPermissionsEnum, {
  featureId: 'IndustryCatalogs',
});
